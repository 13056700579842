/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Paper,
  Button,
  Grid,
  Avatar,
  Divider,
  CardMedia,
  Typography,
  Link,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from '@material-ui/core';
import {
  Search,
  Refresh,
  CheckCircleOutline,
  CheckCircle,
  Cancel,
  Send,
  SaveAlt,
} from '@material-ui/icons';
import {toast, isObject, convDate} from '../../helpers';
import {
  MInput,
  MKeyBoardDatepicker,
  MButton,
  ModalTitle,
  MSelect,
  MDatepicker,
} from '../../components/form';
import {Datatable} from '../../components';
import _ from 'lodash';
import {
  getPaidLeaveHistory,
  putPaidLeaveStatus,
  resetLeaveStatus,
  clearFormMessage,
  exportExcel,
} from './../../redux/paidLeave/paidLeave.actions';
import {PAID_LEAVE_STATE as STATE} from '../../redux/paidLeave/paidLeave.types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {checkStatus as checkStatusUser} from '../../redux/userManagement/userManagement.actions';
import {checkStatus} from '../../redux/organization/organization.actions';
import moment from 'moment';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Checkbox, FormControlLabel} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


class PaidLeave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      query: null,
      startDate: null,
      endDate: null,
      sortBy: null,
      order: 'desc',
      method: 'all',
      showDetail: false,
      showReject: false,
      showApprove: false,
      detailData: null,
      resetDatatable: null,
      resetPage: null,
      status: 'waiting',
      searchBy: null,
      selectedStatus: 'waiting',
      params_id: null,
      formData: {
        total_days: null,
        start_date: null,
        end_date: null,
        approve: null,
        details: null,
        status: '',
        rejected_reason: '',
        file: null,
      },
      errorMessage: null,
      errorFilterDate: false,
      checkboxFilter: false,
      errorMessageApprove: null,
    };
  }

  checkboxTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
  })

  tooltipTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: '18px !important',
            marginLeft: '6px !important',
            marginTop: '-1px !important',
          },
        },
      },
    },
  })

  UNSAFE_componentWillMount() {
    const {
      page,
      limit,
      startDate,
      endDate,
      query,
      sortBy,
      order,
      method,
      status,
      searchBy,
    } = this.state;

    const url_string = window.location.href;
    const url = new URL(url_string);
    const date = url.searchParams.get('date');
    const submission_date = url.searchParams.get('type');
    const statusFilter = url.searchParams.get('status');

    if (statusFilter) {
      this.props.getPaidLeaveHistory({
        ...(page && {page}),
        ...(limit && {limit}),
        ...(query && {query}),
        ...(date && {start_date: date ? convDate(moment(), 'YYYY-MM-DD') : null}),
        ...(date && {end_date: date ? convDate(moment(), 'YYYY-MM-DD') : null}),
        ...(sortBy && {sort_by: sortBy}),
        ...(order && {order}),
        ...(statusFilter && statusFilter !== 'all' && {status: statusFilter}),
        // ...(submission_date && submission_date !== 'all' && {searchBy: submission_date}),
      });
    } else {
        this.props.getPaidLeaveHistory({
          ...(page && {page}),
          ...(limit && {limit}),
          ...(query && {query}),
          ...(date && {start_date: date ? convDate(moment(), 'YYYY-MM-DD') : null}),
          ...(date && {end_date: date ? convDate(moment(), 'YYYY-MM-DD') : null}),
          ...(sortBy && {sort_by: sortBy}),
          ...(order && {order}),
          ...(status && status !== 'all' && {status: status}),
          // ...(submission_date && submission_date !== 'all' && {searchBy: submission_date}),
        });
    }

    this.props.checkStatus();
    this.props.checkStatusUser();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {isError, message, trigger, isSuccessPut, isErrorPut, messagePut, status} = nextProps;

    if (isError && message && !isObject(message)) toast('error', message);

    if (trigger === STATE.PUT_CHANGE_STATUS_SUCCESS && isSuccessPut) {
      if (status === 'approved') {
        toast('success', 'Successfuly Approved Leave');
      } else if (status === 'rejected') {
        toast('success', 'Successfuly Rejected Leave');
      }
      this.handleResetForm(true);
      this.fetchPaidLeave();
      this.props.checkStatus();
    } else if (isErrorPut) {
      toast('error', 'Error Update Leave');
      if (messagePut) {
        this.setState({errorMessage: messagePut, errorMessageApprove: 'You can not approve this request because it has reach the user’s limit quota'});
      }
      setTimeout(() => {
        nextProps.resetLeaveStatus();
      }, 1000);
    }
  }

  componentDidMount() {
    const url_string = window.location.href; // window.location.href
    const url = new URL(url_string);
    const id = url.searchParams.get('id');
    const name = url.searchParams.get('query');
    const statusFilter = url.searchParams.get('status');

    if (statusFilter === 'all') {
      this.setState({status: 'all'});
    }

    if (name) {
      this.setState({query: name}, () => {
        this.handleDebounceSearch(name);
      });
    }
  }

  renderTableTitle = () => {
    const {classes} = this.props;
    return (
      <div className={classes.MUIDataTableToolbar}>
        <div className="d-flex">
          <Typography className={classes.tableTitle}>Leave Management</Typography>
          <ThemeProvider theme={this.tooltipTheme()}>
            <Tooltip title="Click Filter to see all data from leave list">
              <IconButton className={classes.tableTooltip}>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </ThemeProvider>
          {/* <span className="">Remaining Quota: {remaining_quota}</span>   */}
        </div>
      </div>
    );
  };

  fetchPaidLeave = () => {
    const {
      page,
      limit,
      startDate,
      endDate,
      query,
      sortBy,
      order,
      method,
      status,
      searchBy,
    } = this.state;

    const url_string = window.location.href;
    const url = new URL(url_string);
    const date = url.searchParams.get('date');
    const submission_date = url.searchParams.get('type');
    const statusFilter = url.searchParams.get('status');


    this.props.getPaidLeaveHistory({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...((startDate || date) && {start_date: date ? convDate(moment(), 'YYYY-MM-DD') : convDate(startDate)}),
      ...((endDate || date) && {end_date: date ? convDate(moment(), 'YYYY-MM-DD') : convDate(endDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(status && status !== 'all' && {status}),
      ...(searchBy && searchBy !== 'all' && {searchBy}),
    });
  };

  toggleModal = (type, data) => {
    const {paidLeaveData} = this.props;
    if (type === 'detail') {
      const numberId = data.rowData[0]; // get detail attendance from datatable response, based on index
      const selected = paidLeaveData.filter((data) => data.no === numberId);
      const detailData = selected && selected.length ? selected[0] : null;
      const startDate = detailData.start_date ? new Date(detailData.start_date) : null;
      const endDate = detailData.end_date ? new Date(detailData.end_date) : null;
      const file = data && data.rowData && data.rowData[10] ? data.rowData[10] : null;
      this.setState({
        showDetail: true,
        selectedStatus: detailData.status,
        detailData,
        formData: {
          ...this.state.formData,
          total_days: detailData.total_days,
          start_date: startDate,
          end_date: endDate,
          approve: detailData.approve,
          details: detailData.details,
          status: detailData.status,
          rejected_reason: detailData.rejected_reason,
          file: file,
        },
        errorMessage: null,
      });
    } else if (type === 'close') {
      this.setState({showDetail: false, detailData: null, showReject: false, showApprove: false}, () => this.props.clearFormMessage());
    }
  };

  toggleResetDatatable = (status) => {
    this.setState({resetDatatable: status}, () =>
      this.setState({resetDatatable: null}),
    );
  };

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: null}),
    );
  };

  handleReset = () => {
    const state = {
      page: 1,
      limit: 10,
      query: '',
      startDate: convDate(moment()),
      endDate: convDate(moment()),
      sortBy: null,
      order: 'desc',
      method: 'all',
      errorFilterDate: false,
      status: 'all',
      searchBy: 'submission_date',
    };
    this.setState(state, () => this.fetchPaidLeave());
    this.toggleResetDatatable(true);
    this.toggleResetPage();
  };

  handleReload = (params) => {
    this.setState(params, () => this.fetchPaidLeave());
  };

  handleFilterChange = (event) => {
    const {value, name} = event.target;
    this.setState({[name]: value, page: null});
  };

  handleFilterDateChange = (params) => {
    const {value, name} = params;
    this.setState(
        {[name]: value, page: null},
    );
  };

  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({query: value});
    this.handleDebounceSearch(value);
  };

  handleFilterCheckbox = (event) => {
    const {checked} = event.target;

    if (checked) {
      this.setState({
        filterCheckbox: checked,
        startDate: convDate(moment()),
        endDate: convDate(moment()),

      });
      const state = {
        page: 1,
        limit: 10,
        query: '',
        sortBy: null,
        status: 'all',
        order: 'desc',
        method: 'all',
        errorFilterDate: false,
        searchBy: 'submission_date',
      };
      this.setState(state, () => this.fetchPaidLeave());
      this.toggleResetDatatable(true);
      this.toggleResetPage();
    } else {
      this.setState({
        filterCheckbox: checked,
      });

      const state = {
        page: 1,
        limit: 10,
        query: '',
        startDate: null,
        endDate: null,
        sortBy: null,
        status: 'waiting',
        order: 'desc',
        method: 'all',
        errorFilterDate: false,
        searchBy: null,
      };
      this.setState(state, () => this.fetchPaidLeave());
      this.toggleResetDatatable(true);
      this.toggleResetPage();
    }
  }

  handleDebounceSearch = _.debounce((query) => {
    this.toggleResetPage();
    this.setState({page: null}, () => this.fetchPaidLeave());
  }, 500);

  handleInputChange = (event) => {
    const {formData, errorMessage} = this.state;
    const {name, value} = event.target;
    this.setState({
      formData: {...formData, [name]: value},
      errorMessage: {...errorMessage, [name]: null},
      errorMessageApprove: null,
    });
  };

  handleResetForm = (closeModal) => {
    this.setState({
      errorMessage: null,
      formData: {
        total_days: null,
        start_date: null,
        end_date: null,
        approve: null,
        details: null,
        status: '',
        rejected_reason: '',
      },
    });
    if (closeModal) this.setState({showDetail: false, showReject: false, showApprove: false});
  };

  handleChangeStatus = (data, status) => {
    this.setState((prevSate) => ({
      ...prevSate,
      selectedStatus: data[5],
      formData: {
        ...prevSate.formData,
        total_days: data[2],
        start_date: new Date(data[3]),
        end_date: new Date(data[4]),
        approve: data[6],
        status: status,
        details: data[9],
        rejected_reason: '',
      },
      detailData: {
        ...prevSate.detailData,
        id: data[8],
        total_days: data[2],
        start_date: new Date(data[3]),
        end_date: new Date(data[4]),
        status: status,
        approve: data[6],
      },
    }), () => {
      if (status === 'approved') {
        this.setState({showApprove: true});
      } else {
        this.setState({showReject: true});
      }
    });
  }


  changePaidLeaveStatus = (id) => {
    const {formData, showDetail, showReject, showApprove, selectedStatus} = this.state;
    const {status, rejected_reason, total_days, start_date, end_date, approve, details} = formData;
    const errors = {};
    const params = {};
    if (
      showDetail &&
      (
        parseInt(total_days)<=0 ||
        !total_days ||
        !start_date ||
        moment(start_date) > moment(end_date) ||
        !end_date ||
        moment(end_date) < moment(start_date) ||
        !details ||
        (!status || status === 'waiting') ||
        (!rejected_reason && status === 'rejected')
      )) {
      if (parseInt(total_days)<=0) errors.total_days = 'Total Days Minimum 1 Day';
      if (!total_days) errors.total_days = 'Total Days Is Required';
      if (!start_date) errors.start_date = 'Start Date Is Required';
      if (moment(start_date) > moment(end_date)) errors.start_date = `Start Date Can't Greater Then End Date`;
      if (!end_date) errors.end_date = 'End Date Is required';
      if (moment(end_date) < moment(start_date)) errors.start_date = `End Date Can't Lower Then Start Date`;
      if (!approve) errors.approve = 'PIC Is Required';
      if (!details) errors.details = 'Statement Is Required';
      if (!status || status === 'waiting') errors.status = 'Please Select Status';
      if (!rejected_reason && status === 'rejected') errors.rejected_reason = 'Reason Is Required';
      this.setState({errorMessage: errors});
    } else if (showReject && !rejected_reason) {
      errors.rejected_reason = 'Notes Is Required';
      this.setState({errorMessage: errors});
    } else {
      params.total_days = total_days;
      params.start_date = moment(start_date).format('YYYY-MM-DD');
      params.end_date = moment(end_date).format('YYYY-MM-DD');
      params.approve = this.props.profile.full_name;
      params.details = details;
      if (selectedStatus !==status) {
        params.status = status;
      }
      params.rejected_reason = rejected_reason;
      this.props.putPaidLeaveStatus(id, params);
    }
  };

  handleButtonFilter = () => {
    const {
      page,
      limit,
      startDate,
      endDate,
      query,
      sortBy,
      order,
      method,
      status,
      searchBy,
    } = this.state;

    if (startDate === null || endDate === null) {
      return this.setState({
        errorFilterDate: true,
      });
    }

    this.props.getPaidLeaveHistory({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(startDate && {start_date: convDate(startDate)}),
      ...(endDate && {end_date: convDate(endDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(status && status !== 'all' && {status}),
      ...(searchBy && searchBy !== 'all' && {searchBy}),
    });
  }

  handleExport = () => {
    const {page, limit, startDate, endDate, query, sortBy, order, method, completed, status, searchBy} = this.state;
    this.props.exportExcel({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(startDate && {start_date: convDate(startDate)}),
      ...(endDate && {end_date: convDate(endDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(method && method !== 'all' && {method}),
      ...(completed !== null && completed !== undefined && {completed}),
      ...(status && status !== 'all' && {status}),
      ...(searchBy && {searchBy}),
    });
  }

  renderFilter = () => {
    const {classes, message, exportLoading, paidLeaveData} = this.props;
    const {query, startDate, endDate, status, searchBy} = this.state;
    const renderMethodOptions = () => {
      const options = [
        {id: 'all', name: 'All'},
        // {id: 'waiting', name: 'Waiting'},
        {id: 'approved', name: 'Approved'},
        {id: 'rejected', name: 'Rejected'},
      ];
      return options;
    };

    const renderSearchByOptions = () => {
      const options = [
        {id: 'submission_date', name: 'Leave Date'},
        {id: 'confirmed_date', name: 'Confirmed Date'},
      ];
      return options;
    };


    return (
      <Paper variant='outlined' className={classes.paper}>
        {this.state.filterCheckbox ? (
          <>
            <MInput
              autoFocus
              className={`${classes.filter} ${classes.searchInput}`}
              fullWidth={false}
              variant="standard"
              name="query"
              label="Search"
              placeholder="Search keyword"
              value={query}
              onChange={this.handleSearch}
              InputProps={{
                endAdornment: (
                  <IconButton position="end">
                    <Search className={classes.searchIcon} />
                  </IconButton>
                ),
              }}
            />
            <ThemeProvider theme={this.checkboxTheme()}>
              <FormControlLabel
                style={{display: 'inline-flex', verticalAlign: 'bottom'}}
                control={
                  <Checkbox
                    color='primary'
                    checked={this.state.filterCheckbox}
                    onChange={(e) => this.handleFilterCheckbox(e)}
                  />
                }
                label="Filter"
              />
            </ThemeProvider>
            <MSelect
              shrink={true}
              fullWidth={false}
              classNameFC={`${classes.formControlSearchBy} ${classes.filter}`}
              variant="standard"
              name="searchBy"
              label="Search By"
              keyPair={['id', 'name']}
              options={renderSearchByOptions()}
              value={searchBy}
              onChange={this.handleFilterChange}
            />
            <MSelect
              shrink={true}
              fullWidth={false}
              classNameFC={`${classes.formControlStatus} ${classes.filter}`}
              variant="standard"
              name="status"
              label="Status"
              keyPair={['id', 'name']}
              options={renderMethodOptions()}
              value={status}
              onChange={this.handleFilterChange}
            />
            <MKeyBoardDatepicker
              className={classes.filterDatePicker}
              name="startDate"
              label="Start Date"
              value={startDate}
              onDateChange={this.handleFilterDateChange}
              placeholder="dd-mm-yyyy"
              maxDate={endDate ? new Date(endDate) : undefined}
              error={
                this.state.startDate === null && this.state.endDate !== null ? 'Start Date is required' :
                  startDate === null && this.state.errorFilterDate ? 'Start Date is required' :
                    undefined
              }
            />
            <MKeyBoardDatepicker
              className={classes.filterDatePicker}
              name="endDate"
              label="End Date"
              value={endDate}
              onDateChange={this.handleFilterDateChange}
              placeholder="dd-mm-yyyy"
              minDate={new Date(startDate)}
              error={
                this.state.startDate !== null && this.state.endDate === null ? 'End Date is required' :
                  endDate === null && this.state.errorFilterDate ? 'End Date is required' :
                    undefined
              }
            />
            <div className={classes.btnFilterContainer}>
              <MButton
                className={classes.filterBtn}
                label="Apply"
                color="primary"
                onClick={this.handleButtonFilter}
              />
              <MButton
                className={classes.filterBtn}
                label="Reset"
                color="primary"
                icon={<Refresh />}
                onClick={this.handleReset}
              />
              <MButton
                className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                label="Export"
                icon={<SaveAlt />}
                onClick={this.handleExport}
                loading={exportLoading}
                disabled={paidLeaveData?.length < 1 ? true : false}
              />
            </div>
          </>
        ) : (
          <Grid container justifyContent='space-between'>
            <Grid item>
              <MInput
                autoFocus
                className={`${classes.filter} ${classes.searchInput}`}
                fullWidth={false}
                variant="standard"
                name="query"
                label="Search"
                placeholder="Search keyword"
                value={query}
                onChange={this.handleSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton position="end">
                      <Search className={classes.searchIcon} />
                    </IconButton>
                  ),
                }}
              />
              <ThemeProvider theme={this.checkboxTheme()}>
                <FormControlLabel
                  style={{display: 'inline-flex', verticalAlign: 'bottom'}}
                  control={
                    <Checkbox
                      color='primary'
                      checked={this.state.filterCheckbox}
                      onChange={(e) => this.handleFilterCheckbox(e)}
                    />
                  }
                  label="Filter"
                />
              </ThemeProvider>
            </Grid>
            <Grid item>
              <div className={classes.btnFilterContainer}>
                <MButton
                  className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                  label="Export"
                  icon={<SaveAlt />}
                  onClick={this.handleExport}
                  disabled={paidLeaveData?.length < 1 ? true : false}
                  loading={exportLoading}
                />
                <br />
              </div>
            </Grid>
          </Grid>
        )}
      </Paper>
    );
  };

  renderModalDetail = () => {
    const {classes, isLoadingPut} = this.props;
    const {showDetail, detailData, formData, errorMessage} = this.state;
    const {file} = this.state.formData;
    const name = detailData && detailData.name ? detailData.name : '-';
    const total_days =
      formData && formData.total_days ? formData.total_days : 0;
    const start_date =
      formData && formData.start_date ? formData.start_date : null;
    const end_date =
      formData && formData.end_date ? formData.end_date : null;
    const details = formData && formData.details ? formData.details : '';
    const status = formData && formData.status ? formData.status : '';
    const approve = formData && formData.approve ? formData.approve : '';
    const approvedBy =
    detailData && detailData.approvedBy ? detailData.approvedBy : '-';
    const rejected_reason =
      formData && formData.rejected_reason ?
        formData.rejected_reason :
        '';
    const id = detailData && detailData.id ? detailData.id : null;
    const options = [
      {id: 'rejected', name: 'Rejected'},
      {id: 'approved', name: 'Approved'},
    ];

    return (
      <Dialog
        open={showDetail}
        onClose={() => this.toggleModal('close')}
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
      >
        <ModalTitle
          className={classes.modalTitle}
          title="Leave Details"
          onClick={() => this.toggleModal('close')}
        />
        <DialogContent className={classes.dialogContent}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={3}>
              <Typography>Name </Typography>
            </Grid>
            <Grid item>
              <Typography> : </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography>{name}</Typography>
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item xs={3}>
              <Typography>Approved By </Typography>
            </Grid>
            <Grid item>
              <Typography> : </Typography>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography>{approvedBy} </Typography>
            </Grid>
          </Grid>
          <Grid>
            <MInput
              classNameFC={classes.formControl}
              name="total_days"
              type="number"
              label="Total Days *"
              placeholder="Fill in totay days"
              defaultValue={total_days}
              onBlur={this.handleInputChange}
              error={
            errorMessage && errorMessage.total_days ?
              errorMessage.total_days :
              undefined
              }
            />
          </Grid>
          <Grid>
            <MDatepicker
              className={classes.formControl}
              name="start_date"
              label="Start Date"
              value={start_date}
              onDateChange={this.changeValueDate}
              error={
                errorMessage && errorMessage.start_date ? errorMessage.start_date : undefined
              }
              placeholder="dd-mm-yyyy"
              inputVariant="outlined"
              views={['year', 'month', 'date']}
              fullWidth
            />
          </Grid>
          <Grid>
            <MDatepicker
              className={classes.formControl}
              name="end_date"
              label="End Date"
              value={end_date}
              onDateChange={this.changeValueDate}
              error={
                errorMessage && errorMessage.end_date ? errorMessage.end_date : undefined
              }
              placeholder="dd-mm-yyyy"
              minDate={start_date}
              inputVariant="outlined"
              views={['year', 'month', 'date']}
              fullWidth
            />
          </Grid>
          <Grid>
            <MInput
              classNameFC={classes.formControl}
              name="approve"
              label="PIC *"
              placeholder="Fill in pic"
              defaultValue={approve}
              onBlur={this.handleInputChange}
              error={
            errorMessage && errorMessage.approve ?
              errorMessage.approve :
              undefined
              }
            />
          </Grid>
          <Grid>
            <MInput
              classNameFC={classes.formControl}
              multiline
              rows={2}
              rowsMax={3}
              name="details"
              label="Leave Statement *"
              placeholder="Fill in leave statement"
              defaultValue={details}
              onBlur={this.handleInputChange}
              error={
            errorMessage && errorMessage.details ?
              errorMessage.details :
              undefined
              }
            />
          </Grid>
          <Grid>
            <MSelect
              autoFocus
              fullWidth
              classNameFC={classes.formControl}
              name="status"
              label="Status"
              placeholder="Confirm status"
              keyPair={['id', 'name']}
              options={options}
              defaultValue={status}
              onChange={this.handleInputChange}
              error={
            errorMessage && errorMessage.status ?
              errorMessage.status :
              undefined
              }
            />
          </Grid>
          <Grid>
            <MInput
              classNameFC={classes.formControl}
              multiline
              rows={2}
              rowsMax={3}
              name="rejected_reason"
              label="Notes"
              placeholder="Fill in notes"
              defaultValue={rejected_reason}
              onBlur={this.handleInputChange}
              error={
            errorMessage && errorMessage.rejected_reason ?
              errorMessage.rejected_reason :
              undefined
              }
            />
          </Grid>
          {file && (
            <Grid>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs={3}>
                  <Typography>Attachment </Typography>
                </Grid>
                <Grid item>
                  <Typography> : </Typography>
                </Grid>
              </Grid>
              <Link href={file} target="_blank" rel="noopener noreferrer">
                <CardMedia
                  className={classes.imgSize}
                  title="Attachment"
                  image={file}
                />
              </Link>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <MButton className={classes.cancelBtn} label="Cancel" onClick={() => this.toggleModal('close')} />
          <MButton
            className={classes.defaultBtn}
            label="Update"
            icon={<Send />}
            onClick={() => {
              this.changePaidLeaveStatus(id);
            }}
            loading={isLoadingPut}
          />
        </DialogActions>
      </Dialog>
    );
  };


  renderModalRejected = () => {
    const {classes, isLoadingPut} = this.props;
    const {showReject, detailData, errorMessage} = this.state;
    const rejected_reason =
      detailData && detailData.rejected_reason ?
        detailData.rejected_reason :
        '';
    const id = detailData && detailData.id ? detailData.id : null;
    return (
      <Dialog
        open={showReject}
        onClose={() => this.toggleModal('close')}
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
      >
        <ModalTitle
          className={classes.modalTitle}
          title="Leave Reject"
          onClick={() => this.toggleModal('close')}
        />
        <DialogContent className={classes.dialogContent}>
          <MInput
            classNameFC={classes.formControl}
            multiline
            rows={2}
            rowsMax={3}
            name="rejected_reason"
            label="Notes"
            placeholder="Fill in notes"
            defaultValue={rejected_reason}
            onBlur={this.handleInputChange}
            error={
              errorMessage && errorMessage.rejected_reason ?
                errorMessage.rejected_reason :
                undefined
            }
          />
        </DialogContent>
        <DialogActions>
          <MButton className={classes.cancelBtn} label="Cancel" onClick={() => this.toggleModal('close')} />
          <MButton
            className={classes.rejectBtn}
            label="Reject"
            icon={<Send />}
            onClick={() => this.changePaidLeaveStatus(id)}
            loading={isLoadingPut}
          />
        </DialogActions>
      </Dialog>
    );
  };
  renderModalApproved = () => {
    const {classes, isLoadingPut} = this.props;
    const {showApprove, detailData, errorMessage, errorMessageApprove} = this.state;
    const rejected_reason =
      detailData && detailData.rejected_reason ?
        detailData.rejected_reason :
        '';
    const id = detailData && detailData.id ? detailData.id : null;
    return (
      <Dialog
        open={showApprove}
        onClose={() => this.toggleModal('close')}
        maxWidth="sm"
        fullWidth={true}
        scroll="paper"
      >
        <ModalTitle
          className={classes.modalTitle}
          title="Leave Approve"
          onClick={() => this.toggleModal('close')}
        />
        <DialogContent className={classes.dialogContent}>
          <MInput
            classNameFC={classes.formControl}
            multiline
            rows={2}
            rowsMax={3}
            name="rejected_reason"
            label="Notes"
            placeholder="Fill in notes"
            defaultValue={rejected_reason}
            onBlur={this.handleInputChange}
            error={
              errorMessage && errorMessage.rejected_reason ?
              errorMessage.rejected_reason :
              undefined}
              autoFocus
          />
        </DialogContent>
        <DialogActions>
          <MButton className={classes.cancelBtn} label="Cancel" onClick={() => this.toggleModal('close')} />
          <MButton
            className={classes.rejectBtn}
            label="Approve"
            icon={<Send />}
            onClick={() => this.changePaidLeaveStatus(id)}
            loading={isLoadingPut}
          />
        </DialogActions>
      </Dialog>
    );
  };

  renderActions = (params) => {
    return (
      <div style={{whiteSpace: 'nowrap'}}>
        {params[5] && params[5] === 'waiting' ? (
          <>
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Tooltip title="Approved">
                <Button
                  variant="contained"
                  // color="primary"
                  style={{
                    'backgroundColor': '#008000',
                    'boxShadow': 'none',
                    'color': '#fff',
                    '&:hover': {
                      boxShadow: 'none',
                      backgroundColor: '#007000',
                    },
                    'minWidth': '10px',
                    'paddingRight': '0px',
                  }}
                  size="small"
                  onClick={() => this.handleChangeStatus(params, 'approved')}
                  startIcon={<CheckCircle />}
                >
                </Button>
              </Tooltip>
            </div>
            <div style={{display: 'inline', marginRight: '5px'}}>
              <Tooltip title="Rejected">
                <Button
                  variant="contained"
                  // color="primary"
                  size="small"
                  style={{
                    'backgroundColor': '#E5AF5A',
                    'boxShadow': 'none',
                    'color': '#fff',
                    '&:hover': {
                      boxShadow: 'none',
                      backgroundColor: '#D09F53',
                    },
                    'minWidth': '10px',
                    'paddingRight': '0px',
                  }}
                  onClick={() => this.handleChangeStatus(params, 'rejected')}
                  startIcon={<Cancel />}
                >
                </Button>
              </Tooltip>
            </div>
          </>
        ) : null}
      </div>
    );
  };

  changeValueDate = (parms) => {
    const {name, value} = parms;
    const {formData} = this.state;
    const {end_date} = formData;
    const val_date = new Date(value);
    const val_end_date = end_date;
    if (name === 'start_date') {
      if (val_date > val_end_date) {
        this.setState({
          formData: {
            ...this.state.formData,
            total_days: 1,
            start_date: val_date,
            end_date: val_date,
          },
        });
      } else {
        this.setState({
          formData: {
            ...this.state.formData,
            start_date: val_date,
          },
        });
      }
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
          end_date: val_date,
        },
      });
    }
  }

  render() {
    const {classes, paidLeaveData, paidLeaveMeta, isLoading} = this.props;
    const {resetDatatable, resetPage} = this.state;
    const columns = [
      {name: 'no', label: 'No', display: true},
      {
        name: 'name',
        label: 'Name',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'total_days',
        label: 'Total Days',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {
        name: 'start_date',
        label: 'Start Date',
        display: true,
        customBodyRender: (value) => {
          return <div style={{whiteSpace: 'nowrap'}}>{value ? convDate(value, 'DD-MM-YYYY') : '-'}</div>;
        },
      },
      {
        name: 'end_date',
        label: 'End Date',
        display: true,
        customBodyRender: (value) => {
          return <div style={{whiteSpace: 'nowrap'}}>{value ? convDate(value, 'DD-MM-YYYY') : '-'}</div>;
        },
      },
      {
        name: 'status',
        label: 'Status',
        display: true,
        customBodyRender: (value) =>
          value ? value.charAt(0).toUpperCase() + value.slice(1) : '-',
      },
      {
        name: 'approve',
        label: 'PIC',
        display: false,
      },
      {
        name: 'approvedBy',
        label: 'Approved By',
        display: true,
        customBodyRender: (value) => (value ? value : '-'),
      },
      {name: 'id', display: false},
      {
        name: 'details',
        label: 'Leave Statement',
        display: false,
      },
      {
        name: 'file',
        label: 'Attachment',
        display: false,
      },
    ];

    return (
      <div className={classes.root}>
        {this.renderFilter()}
        <Datatable
          creatable={false}
          title={this.renderTableTitle()}
          loading={isLoading}
          dataSource={paidLeaveData}
          total={paidLeaveMeta.total}
          page={paidLeaveMeta.page}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          handleDetail={(params) => this.toggleModal('detail', params)}
          customActions={(params) => this.renderActions(params)}
          toggleResetAll={resetDatatable}
          toggleResetPage={resetPage}
          manualNumbering={true}
        />
        {this.renderModalDetail()}
        {this.renderModalRejected()}
        {this.renderModalApproved()}
      </div>
    );
  }
}

const mapStateToProps = ({auth, paidLeave}) => {
  return {
    profile: auth.data.profile,
    isLoading: paidLeave.isLoading,
    isSuccess: paidLeave.isSuccess,
    isError: paidLeave.isError,
    message: paidLeave.message,
    paidLeaveData: paidLeave.paidLeaveData,
    paidLeaveMeta: paidLeave.paidLeaveMeta,
    detailLoading: paidLeave.detailLoading,
    detailData: paidLeave.detailData,
    trigger: paidLeave.trigger,

    isLoadingPut: paidLeave.isLoadingPut,
    isSuccessPut: paidLeave.isSuccessPut,
    isFailurePut: paidLeave.isFailurePut,
    isErrorPut: paidLeave.isErrorPut,
    messagePut: paidLeave.messagePut,
    status: paidLeave.status,
    exportLoading: paidLeave.exportLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPaidLeaveHistory: (params) => dispatch(getPaidLeaveHistory(params)),
  putPaidLeaveStatus: (id, params) =>
    dispatch(putPaidLeaveStatus(id, params)),
  resetLeaveStatus: () => dispatch(resetLeaveStatus()),
  clearFormMessage: () => dispatch(clearFormMessage()),
  checkStatus: () => dispatch(checkStatus()),
  checkStatusUser: () => dispatch(checkStatusUser()),
  exportExcel: (params) => dispatch(exportExcel(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(PaidLeave));
