/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {withStyles, createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import styles from './styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import {
  Delete,
  Edit,
  HelpOutline,
  Search,
  Send,
} from '@material-ui/icons';
import {toast, isObject, showPopup} from '../../../helpers';
import {MInput, MButton, ModalTitle, MSelect, MDatepicker} from '../../../components/form';
import {LeaveDatatable} from '../../../components';
import {LEAVE_CONFIG_STATE as STATE} from '../../../redux/leaveConfig/leaveConfig.types';
import {checkStatus as checkStatusUser} from '../../../redux/userManagement/userManagement.actions';
import {checkStatus} from '../../../redux/organization/organization.actions';
import {
  getLeaveConfig,
  createLeaveConfig,
  deleteLeaveConfig,
  updateLeaveConfig,
  clearState,
} from '../../../redux/leaveConfig/leaveConfig.actions';
import moment from 'moment';

class LeaveConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      query: null,
      startDate: null,
      endDate: null,
      sortBy: null,
      order: 'desc',
      method: 'all',
      completed: null,
      showDetail: false,
      resetDatatable: null,
      detailData: null,
      resetPage: null,
      modalFormShow: false,
      isEdit: false,
      formData: {
        id: null,
        year: null,
        quota: null,
        accumulative: null,
      },
      errorMessage: null,
    };
  }

  getMuiTheme = () => createTheme({
    overrides: {
      MuiTable: {
        root: {
          width: '123%',
        },
      },
      MuiTypography: {
        body1: {
          fontSize: '18px',
          fontWeight: '700',
          color: '#656464',
          fontFamily: 'Poppins !important',
        },
      },
    },
  });

  tooltipTheme = () => createTheme({
    palette: {
      secondary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiSvgIcon: {
        root: {
          fontSize: 16,
          marginTop: 1,
        },
      },
      MuiIconButton: {
        root: {
          padding: 0,
        },
      },
    },
  })
  UNSAFE_componentWillMount() {
    // this.props.checkStatus(); // check company feature status availability {remote, business, office}
    this.fetchLeave();
    this.props.checkStatus();
    this.props.checkStatusUser();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, isSuccess, isError, message} = nextProps;

    if (isSuccess) {
      if (trigger === STATE.CREATE_SUCCESS || trigger === STATE.UPDATE_SUCCESS || trigger === STATE.DROP_SUCCESS) {
        this.handleResetForm(true);
        this.fetchLeave();
        toast('success', message);
      }
    }

    if (isError && message) {
      if (isObject(message)) {
        this.setState({errorMessage: message});
        // toast('error', message.year);
        // toast('error', message.quota);
      }
      if (!isObject(message)) toast('error', message);
    }
  }

  componentDidMount() {
    // this.handleCheckQueryParam();
  }

  componentWillUnmount() {
    this.props.clearState();
  }

  handleFormToggle = (type, data = null) => {
    const {leaveConfigData} = this.props;
    const leaveMap = leaveConfigData;
    const datas = leaveMap.filter((value) => {
      if (data !== null) {
        return value.year === data[1];
      } else {
        return;
      }
    });
    if (type === 'edit') {
      this.setState({
        isEdit: true,
        modalFormShow: !this.state.modalFormShow,
        formData: {
          id: datas[0].id,
          year: datas[0].year,
          quota: datas[0].quota,
          accumulative: datas[0].accumulative,
        },
      });
    } else {
      this.handleResetForm();
      this.setState({
        isEdit: false,
        modalFormShow: !this.state.modalFormShow,
      });
    }
  }

  handleResetForm = (closeModal) => {
    this.setState({
      formData: {
        id: null,
        year: null,
        quota: null,
        accumulative: null,
      },
      errorMessage: null,
      modalFormShow: false,
    });
    if (closeModal) this.setState({showModal: false});
  }

  handleReload = (params) => {
    this.setState(
        params,
        () => this.fetchLeave(),
    );
  }

  fetchLeave = () => {
    const {page, limit} = this.state;
    this.props.getLeaveConfig({page, limit});
  }

  handleInputChange = (event, limit) => {
    const {name, value} = event.target;
    if (isNaN(value)) {
      event.preventDefault();
      return;
    }
    if (event.which === 45 || event.which === 189) {
      event.preventDefault();
      return;
    }
    if (value.length >= limit) {
      if (event.keyCode >= 48 && event.keyCode <= 90) {
        event.preventDefault();
        return;
      }
    }

    const val = event.keyCode;
    const target = event.target ? event.target : event.srcElement;
    if (target.value.length === 0 && val === 48) {
      event.preventDefault();
      return false;
    } else if ((val >= 48 && val < 58) || ((val > 96 && val < 106)) || val === 46 || val === 8 || val === 127 || val === 189 || val === 109 || val === 45 || val === 9) {
      return true;
    } else {
      event.preventDefault();
      this.setState({
        formData: {...this.state.formData, [name]: value ? parseInt(value) : null},
        errorMessage: {...this.state.errorMessage, [name]: null},
      });
      return false;
    }
  }

  handleDateChange = (event) => {
    this.setState({
      formData: {...this.state.formData, year: event?.value?.getFullYear()},
      errorMessage: {...this.state.errorMessage},
    });
  }

  handleConfirmDelete = (data) => {
    const {leaveConfigData} = this.props;
    const leaveMap = leaveConfigData;
    const idGet = leaveMap.filter((value) => {
      return value.year === data[1];
    });
    showPopup(
        'confirm',
        'Are you sure you want to delete this data ?',
        'Yes',
        () => this.props.deleteLeaveConfig(idGet[0].id),
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {createLeaveConfig, updateLeaveConfig, leaveConfigData} = this.props;
    const {isEdit} = this.state;
    const {id, year, quota, accumulative} = this.state.formData;

    const newData = leaveConfigData.filter((value) => {
      return value.id === id;
    });
    let params;
    if (isEdit) {
      if (newData[0].year === year) {
        params = {
          ...(quota && {quota}),
          ...(accumulative && {accumulative}),
        };
      } else {
        params = {
          ...(year && {year}),
          ...(quota && {quota}),
          ...(accumulative && {accumulative}),
        };
      }
    } else {
      params = {
        ...(year && {year}),
        ...(quota && {quota}),
        ...(accumulative && {accumulative}),
      };
    }
    // this.setState({
    //   modalFormShow: false
    // })
    return (!isEdit) ? createLeaveConfig(params) : updateLeaveConfig(id, params);
  }

  handleFilterChange = (event) => {
    const {value, name} = event.target;
    this.setState({
      formData: {...this.state.formData, [name]: value !== undefined ? value : null},
      errorMessage: {...this.state.errorMessage, [name]: null},
    });
  };

  renderModalForm = (params) => {
    const {
      classes,
    } = this.props;
    const {
      modalFormShow,
      isEdit,
    } = this.state;
    const titleDialog = isEdit ? 'Update Leave' : 'Create Leave';
    const {year, quota, accumulative} = this.state.formData;
    const {errorMessage} = this.state;

    const renderAccumulativeOption = () => {
      const options = [
      ];
      for (let i = 0; i <= 3; i++) {
        options.push({id: i, name: i});
      }
      return options;
    };

    return (
      <Dialog
        open={modalFormShow}
        onClose={() => this.handleFormToggle('close')}
        maxWidth='sm'
        fullWidth={true}
        scroll='paper'
      >
        <ModalTitle
          title={titleDialog}
          onClose={() => this.handleFormToggle('close')}
        />
        <form onSubmit={this.handleSubmit} autoComplete="off">
          <DialogContent className={classes.dialogContent}>
            <input type="hidden" value="no-autocomplete" />

            <MDatepicker
              className={classes.formControl}
              format="yyyy"
              inputVariant="outlined"
              name="year"
              label="Year *"
              value={year ? new Date(year, 0) : new Date()}
              placeholder="yyyy"
              views={['year']}
              minDate={new Date()}
              onDateChange={this.handleDateChange}
              error={
                errorMessage && errorMessage.year ?
                  errorMessage.year :
                  undefined
              }
            />

            <MInput
              classNameFC={classes.formControl}
              name="quota"
              label="Total Leave"
              placeholder="Total Leave *"
              defaultValue={quota}
              onKeyDown={(event) => this.handleInputChange(event, 3)}
              onBlur={(event) => this.handleInputChange(event, 3)}
              onChange={(event) => this.handleInputChange(event, 3)}
              type="number"
              error={
                errorMessage && errorMessage.quota ?
                  errorMessage.quota :
                  undefined
              }
              inputProps={{
                max: 365,
              }}
            />
            <div>
              <MSelect
                classNameFC={classes.formControl}
                variant="outlined"
                name="accumulative"
                label="Accumulative Leave in year"
                keyPair={['id', 'name']}
                options={renderAccumulativeOption()}
                value={accumulative}
                onChange={this.handleFilterChange}
                error={
                  errorMessage && errorMessage.accumulative ?
                    errorMessage.accumulative :
                    undefined
                }
              />
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}>
                <Typography style={{fontSize: 12, fontWeight: 400}}>Accumulative Leave Information</Typography>
                <MuiThemeProvider theme={this.tooltipTheme()}>
                  <Tooltip
                  placement='bottom-end'
                  title="if you fill this field with numbers means that your company’s leave will be accumulated as much as numbers (in year).
                  Example: Company ABC’s leave total will be expired in 3 years, so it should be filled with 3."
                  followCursor
                  >
                    <IconButton style={{marginLeft: 4, bottom: 1}}>
                      <HelpOutline />
                    </IconButton>
                  </Tooltip>
                </MuiThemeProvider>
              </div>
            </div>
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <MButton
              className={classes.cancelBtn}
              label="Cancel"
              onClick={() => this.handleFormToggle('close')}
            />
            <MButton
              className={classes.defaultBtn}
              label={'Save'}
              type="submit"
              icon={<Send />}
            />
          </DialogActions>
        </form>
      </Dialog>
    );
  };
  renderActions = (params) => {
    const {leaveConfigData} = this.props;
    const leaveMap = leaveConfigData;
    const idGet = leaveMap.filter((value) => {
      return value.year === params[1];
    });
    const year = idGet[0]?.year;
    const currentYear = moment().year();
    return (
      <div style={{display: 'flex', marginLeft: '-4px'}}>
        <Link to ={'/master-holiday?id=' + idGet[0].id}>
          <Tooltip title="DETAIL">
            <div>
            <Button
                variant="contained"
                // color="primary"
                style={{
                  'backgroundColor': '#00AFF0',
                  'boxShadow': 'none',
                  'color': '#fff',
                  '&:hover': {
                    boxShadow: 'none',
                    backgroundColor: '#00A2DE',
                  },
                  'minWidth': '10px',
                  'paddingRight': '0px',
                  'marginRight': '4px',
                }}
                size="small"
                startIcon={<Search />}
              >
              </Button>
            </div>
          </Tooltip>
        </Link>
        {year >= currentYear && (
          <>
            <Tooltip title="EDIT">
              <div>
                <Button
                  variant="contained"
                  // color="primary"
                  style={{
                    'backgroundColor': '#00AFF0',
                    'boxShadow': 'none',
                    'color': '#fff',
                    '&:hover': {
                      boxShadow: 'none',
                      backgroundColor: '#00A2DE',
                    },
                    'minWidth': '10px',
                    'paddingRight': '0px',
                    'marginRight': '4px',
                  }}
                  size="small"
                  startIcon={<Edit />}
                  onClick={() => this.handleFormToggle('edit', params )}
                >
                </Button>
              </div>
            </Tooltip>
            <Tooltip title="DELETE">
              <div>
                <Button
                  variant="contained"
                  // color="primary"
                  size="small"
                  style={{
                    'backgroundColor': '#E5AF5A',
                    'boxShadow': 'none',
                    'color': '#fff',
                    '&:hover': {
                      boxShadow: 'none',
                      backgroundColor: '#D09F53',
                    },
                    'minWidth': '10px',
                    'paddingRight': '0px',
                  }}
                  onClick={() => this.handleConfirmDelete(params)}
                  startIcon={<Delete />}>
                </Button>
              </div>
            </Tooltip>
          </>
        )}
      </div>
    );
  };
  renderAddButton = () => {
    const {classes} = this.props;
    return (
      <div className={classes.MUIDataTableToolbar}>
        <Typography className={classes.tableTitle}>Leave List</Typography>
      </div>
    );
  }
  render() {
    const {classes, isLoading, leaveConfigData, leaveConfigMeta} = this.props;
    const columns = [
      {name: 'no', label: 'No', display: true},
      {name: 'year', label: 'Year', display: true, customBodyRender: (value) => value ? value : '-'},
      {name: 'quota', label: 'Total Leave', display: true, customBodyRender: (value) => value ? value : '-'},
      {name: 'remaining_quota', label: 'Remaining', display: true, customBodyRender: (value) => value ? value : '0'},
      {name: 'accumulative', label: 'Accumulative Leave', display: false, customBodyRender: (value) => value ? value : '0'},
    ];
    return (
      <div className={classes.root}>
        <LeaveDatatable
          className={classes.MuiTable}
          theme={this.getMuiTheme()}
          creatable={true}
          title={this.renderAddButton()}
          loading={isLoading}
          dataSource={leaveConfigData}
          total={leaveConfigMeta ? leaveConfigMeta.total: 0}
          page={leaveConfigMeta ? leaveConfigMeta.page: 1}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          customActions={(params) => this.renderActions(params)}
          handleCreate={() => this.handleFormToggle('add')}
          manualNumbering={true}
        />
        {this.renderModalForm()}
      </div>
    );
  }
}

const StateToProps = ({leaveConfig}) => ({
  isLoading: leaveConfig.isLoading,
  isSuccess: leaveConfig.isSuccess,
  isError: leaveConfig.isError,
  message: leaveConfig.message,
  trigger: leaveConfig.trigger,
  leaveConfigData: leaveConfig.leaveConfigData,
  leaveConfigMeta: leaveConfig.leaveConfigMeta,
});

const DispatchToProps = (dispatch) => ({
  getLeaveConfig: (params) => dispatch(getLeaveConfig(params)),
  createLeaveConfig: (params) => dispatch(createLeaveConfig(params)),
  updateLeaveConfig: (id, params) => dispatch(updateLeaveConfig(id, params)),
  deleteLeaveConfig: (id) => dispatch(deleteLeaveConfig(id)),
  clearState: () => dispatch(clearState()),
  checkStatus: () => dispatch(checkStatus()),
  checkStatusUser: () => dispatch(checkStatusUser()),

});

export default connect(StateToProps, DispatchToProps)(withStyles(styles)(LeaveConfig));
